import request from './request';
import cloneDeep from 'lodash/cloneDeep';

export default {
  appendSearch(url, opts, search) {
    opts ||= {};
    let qp = [];
    if (search?.length > 0) {
      qp.push(`search=${search}`);
    }

    if (opts.page) {
      qp.push(`page=${opts.page}`);
    }
    if (opts.itemsPerPage) {
      qp.push(`size=${opts.itemsPerPage}`);
    }

    if (opts.filters) {
      for (let filter in opts.filters) {
        let f = opts.filters[filter];
        qp.push(`${f.field}${f.operation}${f.value}`);
      }
    }

    let sort = [];
    for (let i = 0; i < opts.sortBy?.length; i++) {
      let sign = '';
      if (i < opts.sortDesc.length && opts.sortDesc[i]) {
        sign += '-';
      }
      let field = opts.sortBy[i].replace('.', '__');
      sort.push(sign + field);
    }
    if (sort.length > 0) {
      qp.push(`ordering=${sort.join(',')}`);
    }

    let qps = qp.join('&');
    if (qps.length > 0) {
      url += '?' + qps;
    }

    return url;
  },

  async getPlanItems(opts, search) {
    // console.log('getPlanItems.opts:', opts);
    let url = `/api/plan/`;
    url = this.appendSearch(url, opts, search);

    let rsp = await request.request(url, 'get', {}, {});
    return [rsp.data.results, rsp.data.count];
  },

  async getMachinePlan(machineID, options, search, archived) {
    let opts = cloneDeep(options); // to avoid `You may have an infinite update loop in watcher with expression "options"`
    // append archived to opts.filters
    opts.filters ||= {};
    opts.filters.archived = {
      field: 'archived',
      operation: '=',
      value: archived
    };
    // console.log('getMachinePlan.opts:', opts);
    let url = `/api/machines/${machineID}/plan/`;
    url = this.appendSearch(url, opts, search);

    let rsp = await request.request(url, 'get', {}, {});
    return [rsp.data.results, rsp.data.count];
  },

  async refresh() {
    console.log('PlanService.refresh()');
    let url = `/api/plan/refresh/`;
    // console.log("url:", url)
    return await request.request(url, 'put', {}, {});
  },

  async update(id, data) {
    console.log('update ', id, ' to ', data);
    await request.request(`/api/plan/${id}/`, 'patch', data);
  },

  async reset() {
    console.log('PlanService.reset()');
    let url = `/api/plan/reset/`;
    // console.log("url:", url)
    return await request.request(url, 'delete', {}, {});
  },

  async archive(machineID, item) {
    console.log('archive planitem: ', item);
    let id = item.id;
    const result = await request.request(
      `/api/machines/${machineID}/plan/${id}/archive/`,
      'put',
      {
        archived: item.archived
      },
      {}
    );

    console.log('result: ', result);
    return result;
  },

  async drag(machineId, itemId, beforeId) {
    console.log(`PlanService.drag(${itemId} before ${beforeId})`);
    const result = await request.request(
      `/api/machines/${machineId}/plan/${itemId}/drag/`,
      'put',
      {
        machine_id: machineId,
        before: beforeId
      },
      {}
    );

    console.log('result: ', result);
    return result;
  },

  async moveToMachine(machineId, itemId) {
    console.log(`PlanService.moveToMachine(${itemId} -> ${machineId})`);
    const result = await request.request(
      `/api/machines/${machineId}/plan/${itemId}/move_to_machine/`,
      'put',
      {
        machineId: machineId
      },
      {}
    );

    console.log('result: ', result);
    return result;
  },

  async excel(search, archived) {
    console.log(`Generate plan.xlsx, archived=${archived}`);
    let url = `/api/plan/excel/`;
    if (search?.length > 0) {
      url += `?search=${search}`;
    }
    await request.download(url, 'plan.xlsx');
  },

  async getProgress(order_ids) {
    const url = `/api/plan/get_progress/`;
    const result = await request.request(
      url,
      'post',
      {
        order_ids: order_ids
      },
      {}
    );

    return result.data;
  }
};
